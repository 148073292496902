import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import '../../styles/article.scss';
import Contact from '../../components/contact.js';

const Article = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="When to invest in a new website – 54 Bit Studios" 
              description="Investing in a new website is not as daunting as you may think. In this post, we’re sharing the key factors you should know."
              image={data.file.childImageSharp.gatsbyImageData}
              article={true}/>
            <PageHeading h1="When to Invest in a New Website" h2="Blog" blurb="Investing in a new website is not as daunting as you may think. In this post, we’re sharing the key factors you should know." />
            <section className="ff article">
                <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Off-the-shelf Shopify Themes: The pros and cons" />
            </section>
            <section className="ff article">
                <p>A website, whether built from scratch or bought, can quickly become outdated if left untouched. Therefore, as we approach 2025, many organisations will be considering whether to invest in a new Shopify store. With this decision can come some concerns such as it costing too much or taking far too much time and effort (both of which are likely to be untrue). That’s why in this article, we’re exploring what you need to know about a new website, when’s the best time to invest in one and what you should be doing before and after launch.</p>
                <h3>Why consider a new website?</h3>
                <p>Investing in a new website is often a necessary step to propel your business forward. It provides businesses with a blank slate ready to bring their ideas, creativity and vision to life. However, the decision of why and when to invest in one often comes down to the unique circumstances of each business, so there is unfortunately no straightforward answer to this.</p>
                <p>However, these are some common reasons why our clients ultimately choose to invest in a new website:</p>
                <ul>
                    <li><strong>Speed:</strong> Site speed can directly impact your conversion rate. Shopify says a <a href="https://www.shopify.com/uk/blog/site-speed-importance" target="_blank" rel="noreferrer">1 second improvement can enhance conversion rate by up to 27%</a>. Cut the fat and build a new lean store to unlock speed benefits.</li>
                    <li><strong>Outdated technology:</strong> Many stores still haven't migrated to Shopify 2.0 and it can often be easier to start again than go through a lengthy migration.</li>
                    <li><strong>New feature requirements:</strong> When your wish list of new features is getting too long, it becomes sensible to tie that in with a new website in order to build perfectly without needing to tip-toe around legacy features.</li>
                    <li><strong>Brand refresh:</strong> With a new brand comes the requirement to invest in a new look and feel on your website. It often makes sense to tie this in with a new site rather than trying to update your current website bit by bit.</li>
                    <li><strong>Bugs:</strong> If you've been pulling and prodding your ecommerce store, issues inevitably start appearing as more systems and components need to live alongside each other, often with a good amount of legacy code lying about and causing mischief. Over time, issues will increase and it's a good indication that it may be a good time to go back to the drawing board.</li>
                    <li><strong>‘In-house' your apps:</strong> You want to ditch the "patchwork" website look and unify the experience by replacing those services that previously have been plugged in off-the-shelf with bespoke solutions tailored to your business and website. This can also save you the monthly fees going to third parties.</li>
                    <li><strong>Entering new markets:</strong> A new website that's tailored to your new demographic will help you to serve target customers more effectively.</li>
                </ul>
                <p>It could be one of these points or a handful of them that lead you to your decision. However, if you do arrive at the exciting verdict that you need to be investing in a new website, what should you do in advance?</p>
                <h3>What should you do before launching a new website?</h3>
                <p>There are <a href='https://diviflash.com/website-statistics/'>175 new websites every minute</a>. So, making sure you carefully plan in advance the ‘How’, ‘Why’ and ‘When’ can be crucial for a successful launch. To begin, you should have clear goals of what you want your new website to achieve (these often revolve around the points listed above).</p>
                <p>Before launching your new website, be sure to gather data on your current site to identify areas for improvement. This can include traffic, conversion rates, site speed and user behaviour. You can also add a free session recording app onto your site (e.g., <a href='https://clarity.microsoft.com/' target='_blank' rel='noreferrer'>Microsoft Clarity</a>) to understand how your users are shopping and potentially identify friction in their journeys. All of this information will provide a baseline for measuring the success of your new website.</p>
                <p>You should also set a realistic launch date, avoid peak periods (for example, Black Friday, Cyber Monday and Christmas) and create a detailed project plan outlining tasks, deadlines and responsibilities. Give yourself a least a few weeks of contingency at the end of the project to perfect the site and make sure you're happy with everything before that promised launch date.</p>
                <h4>What should you do after launching a new website?</h4>
                <p>Great news! Your site is now live. At this stage, there are some key tasks a web developer will do to ensure your launch is the success you hoped for. Some of which are:</p>
                <ul>
                    <li><strong>Error Checking:</strong> Thoroughly testing the site to identify and fix any bugs - use real devices wherever possible.</li>
                    <li><strong>Session recording app:</strong> Adding a free session recording app to your site (e.g., <a href='https://clarity.microsoft.com/' target='_blank' rel='noreferrer'>Microsoft Clarity</a>) to understand how your users are shopping and identify friction in their journeys.</li>
                    <li><strong>Speed test:</strong> Run a speed audit to understand optimisation opportunities once the website is live.</li>
                    <li><strong>Shopify’s Page load speed report:</strong> Utilising Shopify's page load speed report to track improvements between old and new, as well as those smaller wins post-launch.</li>
                    <li><strong>User experience testing:</strong> Use quantative and qualatative data to confirm that the new user journey is as straight-forward as possible; you're aiming for as few a clicks as possible to get from landing to checkout.</li>
                    <li><strong>Backup:</strong> If you're replacing a Shopify Theme, keeping your old Theme as a backup is crucial should you need to quickly switch back for whatever reason.</li>
                    <li><strong>Technical SEO audit:</strong> Checking there are no errors in the technical SEO to ensure your website is optimised for search engines. After all, you need your customers to find you and <a href='https://ahrefs.com/seo/glossary/organic-search-results' target='_blank' rel='noreferrer'>only 0.63% of Google searchers click on a link from the second results page</a>. This shows how critical it is to get the SEO right.</li>
                    <li><strong>Post-launch improvement list:</strong> Creating a list of improvements that will be implemented in future updates.</li>
                </ul>
                <p>Although this may seem like a lot, a skilled web development team can provide invaluable support during this phase, alleviating any stress and giving you peace of mind that your website is all under control.</p>
                <h3>Don’t 'Build and Bye'</h3>
                <p>The investment for a new website can seem daunting for businesses, but we’re here to tell you that it’s often not as expensive as you think and not a lot of effort is required from your side when working with a web development team. We strongly believe that a new website shouldn’t be stressful, it should be an exciting and empowering process that gets your business to where you need it to be. Once created, we’d always suggest adopting a ‘continuous improvement’ approach to allow you to ensure your new website remains effective and efficient.</p>
                <p>At 54 Bit Studios, we never ‘Build and Bye’ and we work with our clients on an ongoing basis to continuously improve and enhance their websites, whether through small tweaks, site speed optimisations, UX/UI improvements or new feature additions. This proactive approach can help you to maximise your ROI and create a website that generates leads, sales or brand awareness.</p>
            </section>
            <Contact heading="Get in touch" subheading="If you're considering a new website in 2025, we're ready to help. Fill out the contact form below and we'll get back to you as soon as possible." />
        </Layout>
    )
};

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/when-to-invest-in-a-new-website.webp" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Article